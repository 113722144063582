import React, { useEffect, useState } from "react";
import DeleteModel from "../../../../helper/DeleteModel";
import { TableLorder } from "../../../../helper/Lorder";
import { PiTrashBold } from "react-icons/pi";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import SortingTable from "../../../../../Components/commonFunction/SortingTable";
import Instructorimg from "../../../../../images/instructor-profile.png";
import { FaAngleDown } from "react-icons/fa";
import { FaSortDown } from "react-icons/fa6";
import { FaSortUp } from "react-icons/fa6";
import { DataEmpty } from "../../../../../Components/commonFunction/CommonFunction";

const StudentTable = ({
  data,
  modelModel = () => {},
  navigateHndle = () => {},
  verifyHndle = () => {},
  deleteHndle,
  deleteLoading,
  DeleteModelClose,
  setDeleteModelClose,
  tableLoading = false,
}) => {
  const [deleteModel, setDeleteModel] = useState({
    deleteShowType: false,
    deleteId: "",
    deleteItem: "",
  });

  const closeDeleteModel = () => {
    setDeleteModel({
      deleteShowType: false,
      deleteId: "",
      deleteItem: "",
    });
    setDeleteModelClose(false);
  };

  useEffect(() => {
    if (DeleteModelClose) {
      closeDeleteModel();
    }
  }, [DeleteModelClose]);
  // ----
  // sorting
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };
  const sortedData = SortingTable(data, sortConfig);
  // sorting
  return (
    <>
      {!tableLoading ? (
        <>
          {sortedData?.length > 0 ? (
            <div className="table-section table-responsive rounded-[15px] w-full bg-white">
              <table className="w-full h-full table-auto table-layout-fixed">
                <thead className="bg-[#d9d9d9] h-[60px]">
                  <tr>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm   font-semibold text-left px-5 py-3 cursor-pointer"
                      onClick={() => handleSort("name")}
                    >
                      <p className="flex items-center gap-2 tableHeading">
                        <span>Name</span>
                        <span className="iconSort">
                          {sortConfig.direction === "asc" ? (
                            <FaSortUp />
                          ) : (
                            <FaSortDown />
                          )}
                        </span>
                      </p>
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold  text-left px-5 py-3 cursor-pointer"
                      onClick={() => handleSort("certificationNumber")}
                    >
                      <p className="flex items-center gap-2 tableHeading">
                        <span> Certification Number</span>
                        <span className="iconSort">
                          {sortConfig.direction === "asc" ? (
                            <FaSortUp />
                          ) : (
                            <FaSortDown />
                          )}
                        </span>
                      </p>
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3 cursor-pointer"
                      onClick={() => handleSort("expirationDate")}
                    >
                      <p className="flex items-center gap-2 tableHeading">
                        <span> Expiration Date</span>
                        <span className="iconSort">
                          {sortConfig.direction === "asc" ? (
                            <FaSortUp />
                          ) : (
                            <FaSortDown />
                          )}
                        </span>
                      </p>
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3 cursor-pointer"
                      onClick={() => handleSort("email")}
                    >
                      <p className="flex items-center gap-2 tableHeading">
                        <span>Email</span>
                        <span className="iconSort">
                          {sortConfig.direction === "asc" ? (
                            <FaSortUp />
                          ) : (
                            <FaSortDown />
                          )}
                        </span>
                      </p>
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3 cursor-pointer"
                      onClick={() => handleSort("phoneNumber")}
                    >
                      <p className="flex items-center gap-2 tableHeading">
                        <span>Phone Number</span>
                        <span className="iconSort">
                          {sortConfig.direction === "asc" ? (
                            <FaSortUp />
                          ) : (
                            <FaSortDown />
                          )}
                        </span>
                      </p>
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3 cursor-pointer"
                      onClick={() => handleSort("status")}
                    >
                      <p className="flex items-center gap-2 tableHeading">
                        <span>Status</span>
                        <span className="iconSort">
                          {sortConfig.direction === "asc" ? (
                            <FaSortUp />
                          ) : (
                            <FaSortDown />
                          )}
                        </span>
                      </p>
                    </th>
                    <th
                      scope="col"
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData?.map((studentItem) => {
                    const {
                      _id,
                      name,
                      certificationNumber,
                      expirationDate,
                      email,
                      phoneNumber,
                      status,
                      profilepic,
                    } = studentItem;

                    return (
                      <tr
                        className=" h-[60px] border-b border-[#F4F5F9]"
                        key={_id}
                      >
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          <div className="flex ">
                            <div className="">
                              <img
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  borderRadius: "25px",
                                }}
                                src={profilepic ? profilepic : Instructorimg}
                                alt=""
                              />
                            </div>
                            <div className="mt-2 ms-3">{name}</div>
                          </div>
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {certificationNumber}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {expirationDate}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {email}
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          {phoneNumber}
                        </td>

                        <td
                          className="text-[#89868D] text-sm  font-normal px-5 py-3"
                          onClick={() => {
                            if (status === "verify") {
                            } else {
                              verifyHndle(_id);
                            }
                          }}
                        >
                          <button className="text-[#CC4D1D] font-normal text-sm rounded-[5px] py-2 px-4 border border-[#CC4D1D] flex items-center gap-[6px] overflow-hidden text-ellipsis whitespace-nowrap max-w-[200px]">
                            {status === "verify"
                              ? "verify"
                              : "Not Yet Verified"}
                            <span>
                              <FaAngleDown />
                            </span>
                          </button>
                        </td>
                        <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                          <div className="flex items-center gap-3">
                            <button
                              className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                              onClick={() => navigateHndle(_id)}
                            >
                              <MdOutlineRemoveRedEye className="text-[#CC4D1D]" />
                            </button>
                            <button
                              onClick={() => modelModel(studentItem)}
                              className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                            >
                              <FiEdit className="text-[#CC4D1D]" />
                            </button>
                            <button
                              onClick={() =>
                                setDeleteModel({
                                  deleteShowType: true,
                                  deleteId: _id,
                                  deleteItem: name,
                                })
                              }
                              className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                            >
                              <PiTrashBold className="text-[#CC4D1D]" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <DeleteModel
                show={deleteModel?.deleteShowType}
                hide={() =>
                  setDeleteModel({
                    deleteShowType: false,
                    deleteId: "",
                  })
                }
                deleteItem={deleteModel?.deleteItem}
                confimDelete={() => deleteHndle(deleteModel?.deleteId)}
                deleteLoading={deleteLoading}
              />
            </div>
          ) : (
            <DataEmpty />
          )}
        </>
      ) : (
        <TableLorder />
      )}
    </>
  );
};

export default StudentTable;
