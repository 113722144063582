import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import "./CourseManagement.css";
import CustomModal from "../../../Components/customModal";
import CourseManagementList from "./courseManagementList";

const MyCourses = () => {
  const [showModal, setShowModal] = useState(false);
  const [courseSearch, setCourseSearch] = useState("");
  const fieldGroups = [
    {
      layout: "md:grid-cols-1 grid-cols-1", // Single column layout for this group
      fields: [
        {
          id: "cname",
          name: "cname",
          label: "Course Name",
          type: "text",
          placeholder: "",
        },
      ],
    },
    {
      layout: "md:grid-cols-2 grid-cols-1", // Two columns on medium screens, one column on small screens
      fields: [
        {
          id: "sdate",
          name: "sdate",
          label: "Start Date",
          type: "date",
          placeholder: "",
        },
        {
          id: "edate",
          name: "edate",
          label: "End Date",
          type: "date",
          placeholder: "",
        },
      ],
    },
    {
      layout: "md:grid-cols-2 grid-cols-1", // Two columns on medium screens, one column on small screens
      fields: [
        {
          id: "duration",
          name: "duration",
          label: "Duration",
          type: "text",
          placeholder: "",
        },
        {
          id: "cdays",
          name: "cdays",
          label: "Class Days",
          type: "select",
          options: [
            { value: "", label: "Select days" },
            { value: "usa", label: "Monday" },
            { value: "canada", label: "Tuesday" },
            { value: "canada", label: "Wednesday" },
            { value: "canada", label: "Friday" },
            { value: "canada", label: "Saturday" },
            { value: "canada", label: "Sunday" },
            // Add more options here
          ],
        },
      ],
    },
    {
      layout: "md:grid-cols-2 grid-cols-1", // Two columns on medium screens, one column on small screens
      fields: [
        {
          id: "time",
          name: "time",
          label: "Class Time",
          type: "time",
          placeholder: "",
        },
        {
          id: "tzone",
          name: "tzone",
          label: "Time Zone",
          type: "time",
          placeholder: "",
        },
      ],
    },

    {
      layout: "md:grid-cols-1 grid-cols-1", // Single column layout for this group
      fields: [
        {
          id: "location",
          name: "location",
          label: "Location",
          type: "text",
          placeholder: "",
        },
      ],
    },
    {
      layout: "md:grid-cols-1 grid-cols-1", // Single column layout for this group
      fields: [
        {
          id: "price",
          name: "price",
          label: "Monthly Price $",
          type: "text",
          placeholder: "",
        },
      ],
    },
    {
      layout: "md:grid-cols-1 grid-cols-1", // Single column layout for this group
      fields: [
        {
          id: "description",
          name: "description",
          label: "Short Description",
          type: "textarea",
          placeholder: "",
        },
      ],
    },
  ];
  return (
    <div className="course-mangement overflow-y-auto">
      <div className="filter md:mt-5 bg-white rounded-[10px] custom-shadow flex items-center  flex-wrap justify-between p-[20px] md:px-3 items-center md:mb-10 mb-5">
        <div className="relative lg:w-[50%] xl:w-[20%] relative">
          <CiSearch className="text-xl absolute z-50 top-[13px] left-[11px]" />
          <input
            type="search"
            onChange={(e) => {
              setCourseSearch(e.target.value);
            }}
            className="search-bar border border-[#F0F0F0]  rounded-lg focus:outline-0 py-2  0px 1px 4px] text-base relative w-full focus:ring-1 focus:ring-inset focus:ring-[#CC4D1D] font-normal placeholder:text-[14px] placeholder:text-[#8E8E8E] px-9"
            placeholder="Search..."
          />
        </div>
      </div>
      {/* Top filter */}
      {/* Course body */}
      <CourseManagementList courseSearch={courseSearch} />
      {/* Course body */}
      <CustomModal
        heading="Add New Course"
        show={showModal}
        hide={() => setShowModal(false)}
        fieldGroups={fieldGroups}
      />
    </div>
  );
};

export default MyCourses;
