import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import "./CourseManagement.css";
import CustomModal from "../../../Components/customModal";
import CourseManagementList from "./courseManagementList";
import CourseAddModel from "../../../admin/pages/courseManagement/courseManagementList/courseAddModel/CourseAddModel";
import { courseDeleteApiHndle } from "../../../admin/helper/apis/course/CourseApis";
import ToastHandle from "../../../admin/helper/ToastMessage";
import { localStorageAuthUserData } from "../../../admin/helper/AuthUserData";
import axiosInstance, {
  BASE_URL,
} from "../../../Components/apiHeader/axiosInstance";
import { INSTRUCTOR_COURSES_LIST } from "../../../admin/helper/ApiEndPoint";
import SearchFilter from "../../../Components/commonFunction/SearchFilter";
import CourseEditModel from "../../../admin/pages/courseManagement/courseManagementList/courseEditModel/CourseEditModel";

const CourseManagement = () => {
  const [editModel, setEditModel] = useState({ type: false });
  const [showModal, setShowModal] = useState(false);
  const [courseSearch, setCourSearch] = useState("");
  const loginData = localStorageAuthUserData();
  const { detail } = loginData;
  const userIdMain = detail?._id;
  const [tableLoading, setTableLoading] = useState("");
  const [data, setData] = useState([]);
  const filterData = SearchFilter(data, courseSearch);
  const courseManagementGetListApi = async () => {
    setTableLoading(true);
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}${INSTRUCTOR_COURSES_LIST}${userIdMain}`
      );
      if (response.status) {
        setData(response?.data?.data);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setTableLoading(false);
  };

  useEffect(() => {
    courseManagementGetListApi();
  }, []);

  // ------
  const [courseAddModel, setCourseAddModel] = useState({ type: false });

  // delete course api integration
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [DeleteModelClose, setDeleteModelClose] = useState(false);
  const courseDeleteHndleApi = async (id) => {
    setDeleteLoading(true);
    try {
      const res = await courseDeleteApiHndle(id);
      if (res && res.status) {
        courseManagementGetListApi();
        setDeleteModelClose(true);
        setDeleteLoading(false);
      } else {
        ToastHandle("Error: Could not add batch", "danger");
      }
    } catch (error) {
      ToastHandle(error?.response?.data?.message, "danger");
    } finally {
      setDeleteLoading(false);
    }
  };

  // delete course api integration

  return (
    <div className="course-mangement overflow-y-auto">
      {/* Top filter */}
      <div className="filter mt-5 bg-white rounded-[10px] custom-shadow flex flex-wrap justify-between h-[75px]  px-5 items-center lg:mb-10 mb-5">
        <div className="relative lg:w-[50%] xl:w-[20%] relative">
          <CiSearch className="text-xl absolute z-50 top-[13px] left-[11px]" />
          <input
            type="search"
            onChange={(e) => setCourSearch(e.target.value)}
            className=" ps-9 search-bar border border-[#F0F0F0] rounded-lg focus:outline-0 py-2 pr-3 text-base w-full focus:ring-1 focus:ring-inset focus:ring-[#CC4D1D] font-normal placeholder:text-[14px] placeholder:text-[#8E8E8E]"
            placeholder="Search..."
          />
        </div>
        <div className="flex flex-wrap gap-5 sm:mt-0 mt-3">
          <button
            onClick={() => setCourseAddModel({ type: true })}
            className=" bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
          >
            <FiPlus className="text-white text-sm me-1" />
            Add New Course
          </button>
        </div>
      </div>
      {/* Top filter */}
      {/* Course body */}
      <CourseManagementList
        courseSearch={courseSearch}
        data={filterData}
        courseAddModel={courseAddModel}
        setCourseAddModel={setCourseAddModel}
        deleteHndle={(id) => courseDeleteHndleApi(id)}
        deleteLoading={deleteLoading}
        DeleteModelClose={DeleteModelClose}
        setDeleteModelClose={setDeleteModelClose}
        tableLoading={tableLoading}
        modelModel={(data) => {
          setEditModel({ editData: data, type: true });
        }}
      />
      {/* Course body */}
      {/* <CustomModal
        heading="Add New Course"
        show={showModal}
        hide={() => setShowModal(false)}
        fieldGroups={fieldGroups}
      /> */}

      <CourseAddModel
        show={courseAddModel}
        hide={() => setCourseAddModel({ type: false })}
        updateAfterRes={() => courseManagementGetListApi()}
      />
      <CourseEditModel
        show={editModel}
        hide={() => {
          setEditModel({ type: false });
        }}
        updateAfterRes={() => courseManagementGetListApi()}
      />
    </div>
  );
};

export default CourseManagement;
