import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";

const FilterBar = ({
  onSearch,
  showDropdown = true,
  dropdownOptions,
  onDropdownSelect,
  DropdownName,
  showBtn = false,
  showBtnText = null,
  showModal,
  addButtonNotShow
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  return (
    <div className="filter mt-5 bg-white rounded-[10px] custom-shadow flex flex-wrap justify-between h-[75px]  px-5 items-center lg:mb-10 mb-5">
      <div className="relative  filter-custom lg:w-[50%] xl:w-[20%]">
        <CiSearch className="text-xl absolute z-50 top-[13px] left-[11px]" />
        <input
          type="search"
          onChange={(e) => onSearch(e.target.value)}
          className="ps-9 search-bar border border-[#F0F0F0] rounded-lg focus:outline-0 py-2 pr-3 text-base w-full focus:ring-1 focus:ring-inset focus:ring-[#CC4D1D] font-normal placeholder:text-[14px] placeholder:text-[#8E8E8E]"
          placeholder="Search..."
        />
      </div>
      <div className="relative filter-custom flex gap-5">
        {/* {showDropdown &&

                    <div
                        className="dropdown inline-block relative"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <button className="text-[#CC4D1D] font-normal text-sm rounded-[5px] py-2 px-3 inline-flex justify-between items-center border border-[#CC4D1D] focus:outline-0">
                            {DropdownName}
                            <svg className="fill-current h-4 w-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                        </button>
                        <ul
                            className="shadow-xl dropdown-menu absolute top-full left-0 text-gray-700 pt-1 w-[150px] bg-white rounded-lg z-50"
                            style={{ display: dropdownOpen ? 'block' : 'none' }}
                        >
                            {dropdownOptions?.map((option, index) => (
                                <li key={index}>
                                    <p
                                        onClick={() => onDropdownSelect(option)}
                                        className="bg-white hover:bg-[#CC4D1D] hover:text-white text-[#CC4D1D] py-2 px-4 block whitespace-no-wrap cursor-pointer"
                                    >
                                        {option}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </div>
                } */}
        {addButtonNotShow && (
          <>
            {showBtn && (
              <div className="evaluation">
                <button
                  onClick={showModal}
                  className="text-white font-normal text-sm rounded-[5px] py-2 px-3 inline-flex justify-between items-center bg-[#CC4D1D] focus:outline-0"
                >
                  {showBtnText}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FilterBar;
