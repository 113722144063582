import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useLocation } from "react-router-dom";
import Footer from "./footer";

function Layout({ children, profileUpdateData }) {
  const [open, setOpen] = useState("large");
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
      setOpen(window.innerWidth > 1023 ? open : "none");
    };

    window.addEventListener("resize", handleResize);

    // Initial setup
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (
    location.pathname === "/login" ||
    location.pathname === "/login-admin" ||
    location.pathname === "/signup" ||
    location.pathname === "/forgetpassword" ||
    location.pathname === "/login-instructor" ||
    location.pathname === "/register-instructor" ||
    location.pathname === "/register-student"
  ) {
    return children;
  }

  return (
    <div className="grid grid-cols-[auto_1fr] h-screen" 
    // style={{ height: `${height}px` }}
    >
      <Sidebar setOpen={setOpen} open={open} />
      <div className={``} open={open}>
        <Header setOpen={setOpen} open={open} profileUpdateData={profileUpdateData}/>
        <div className="md:p-7 p-4 bg-[#F3F3F3] h-[83.5vh] overflow-y-auto">{children}</div>
        <Footer />
      </div>
    </div>
  );
}

export default Layout;
