import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axiosInstance, {
  BASE_URL,
  config,
} from "../../../../../Components/apiHeader/axiosInstance";
import {
  USERS_GET_EMS_DETAILS,
  USERS_GET_HOSPITAL,
  USERS_UPDATE_EMS,
} from "../../../../helper/ApiEndPoint";
import { imageFrame } from "../../../../../Components/commonFunction/CommonFunction";
import ToastHandle from "../../../../helper/ToastMessage";
import { IoIosCall } from "react-icons/io";
import { HiMail } from "react-icons/hi";
import { FaEdit } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import CustomModal from "../../../../../Components/customModal";
import { TableLorder } from "../../../../helper/Lorder";
import { createEditType } from "../../../../helper/AuthUserData";

const EmsBoardView = () => {
  const [showModel, setShowModel] = useState(false);
  const { id } = useParams();
  const profileGetInformation = id ? JSON.parse(id) : {};
  const { id: profileid, type } = profileGetInformation || {};
  // const { emsBoard, hospitalM } = profileInterFaceType || {};

  const [data, setData] = useState([]);
  const {
    apnumber: showApNumber,
    doctor: showDoctor,
    program: showProgram,
    hospital:showHospital
  } = data;
  console.log(data,'datadatadata')

  const [profileShow, setProfileShow] = useState({
    profile: [],
    profileImage: null,
  });
  const { profile } = profileShow || {};

  const emsProfileDataGetApi = async (id) => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}${USERS_GET_EMS_DETAILS}${id}`
      );
      if (response.status) {
        const emsProfileData = response?.data?.detail;

        const convertData = emsProfileData?.map((emsItem, emsIndex) => {
          const {
            _id,
            approval_number,
            doctor,
            hoteldetail,
            image,
            program_coordinator,
          } = emsItem;
          return {
            _id,
            Sr: emsIndex + 1,
            apnumber: approval_number,
            hospital: hoteldetail?.map((hotelName) => {
              return hotelName?.name;
            }),
            hoteldetail,
            doctor: doctor,
            program: program_coordinator,
            signature: image,
          };
        });

        setData(convertData?.[0]);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
  };
  // edit functionality

  //=
  const [dataManagement, setDataManagement] = useState([]);
  const [emsDataLoading, setEmsDataLoading] = useState(false);

  const hospitalManagementGetListApi = async () => {
    setEmsDataLoading(true);
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}${USERS_GET_HOSPITAL}`
      );
      if (response.status) {
        const hospitalData = response?.data?.detail;
        const convertData = hospitalData?.map((hopital, hopitalIndex) => {
          const {
            _id,
            name,
            location,
            ems_coordinator,
            medical_control,
            image,
          } = hopital;
          return {
            Sr: hopitalIndex + 1,
            hospitalname: name,
            location: location,
            ems: ems_coordinator,
            medicalcontrol: medical_control,
            hospitalimage: image[0],
            _id,
          };
        });
        setDataManagement(convertData);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setEmsDataLoading(false);
  };
  //=

  const [fieldGroups, setFieldGroups] = useState("");
  const [editGetData, setEditGetData] = useState("");

  const { _id, apnumber, doctor, hospital, program, signature, hoteldetail } =
    editGetData;

  useEffect(() => {
    if (editGetData !== "" || showModel) {
      setFieldGroups([
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "approvalnumber",
              name: "approvalnumber",
              label: "Approval Number",
              type: "text",
              value: apnumber || "",
              placeholder: "",
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "hospital",
              name: "hospitalId",
              label: "Hospital",
              type: "selectNormal",
              value:
                Array.isArray(hoteldetail) && hoteldetail.length > 0
                  ? hoteldetail[0]
                  : "",
              options: dataManagement?.map((management) => {
                const { _id, hospitalname } = management;
                return { value: _id, name: hospitalname };
              }),
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "doctor",
              name: "doctor",
              label: "Doctor",
              type: "text",
              value: doctor || "",
              placeholder: "",
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "procoordinate",
              name: "programCoordinator",
              label: "Program Coordinator",
              type: "text",
              value: program || "",
              placeholder: "",
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "signature",
              name: "signatureImage",
              label: "Signature",
              type: "file",
              placeholder: "",
              imageShow: signature || "",
            },
          ],
        },
      ]);
    }
  }, [editGetData, showModel]);

  //// edit api
  const [loading, setLoading] = useState(false);
  const emsBoardManagementAddApi = async (data, type) => {
    if (type === createEditType?.edit) {
      setLoading(true);
      const {
        approvalnumber,
        doctor,
        programCoordinator,
        signatureImage,
        hospitalId,
      } = data;
      let formData = new FormData();
      formData.append("id", _id);
      formData.append("approval_number", approvalnumber);
      formData.append("hospital", hospitalId);
      formData.append("doctor", doctor);
      formData.append("program_coordinator", programCoordinator);
      formData.append(
        "image",
        signatureImage[0] !== undefined ? signatureImage[0] : signature
      );
      try {
        const response = await axiosInstance.post(
          `${BASE_URL}${USERS_UPDATE_EMS}`,
          formData,
          config
        );
        const responseMessage = response?.data?.message;
        if (response.status) {
          ToastHandle(responseMessage, "success");
          emsProfileDataGetApi(profileid);
          setShowModel(false);
          setEditGetData("");
          setFieldGroups("");
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          ToastHandle(error?.response?.data?.message, "danger");
        }
      }
      setLoading(false);
    }
  };
  // edit funtionality

  useEffect(() => {
    if (profileid) {
      emsProfileDataGetApi(profileid);
    }
  }, [profileid]);
  useEffect(() => {
    hospitalManagementGetListApi();
  }, []);

  return (
    <>
      {!emsDataLoading ? (
        <>
          <div className="flex  gap-[10px] justify-end">
            <button
              className=" bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
              onClick={() => {
                if (data) {
                  setEditGetData(data);
                  setShowModel(true);
                }
              }}
            >
              <FaEdit />
            </button>
            <Link
              to={`/ems-board-list`}
              className=" bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
            >
              <GrClose />
            </Link>
          </div>
          <div className="profile-section overflow-y-auto student-profile">
            <div className="flex gap-3 md:gap-5 2xl:gap-8 flex-wrap">
              <div className="profile-img rounded-[10px] md:px-5 px-3s py-10 bg-section w-full md:w-[30%] lg:w-[30%] 2xl:w-[20%] ">
                <div className="mb-3">
                  {imageFrame(data?.signature?.[0], "large")}
                </div>
                <div className="social-icon">
                  <ul className="flex justify-center items-center gap-2 mt-3">
                    <li>
                      <Link src="/">
                        <IoIosCall />
                      </Link>
                    </li>
                    <li>
                      <Link src="/">
                        <HiMail />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-full md:w-[67%] lg:w-[65%] 2xl:w-[77%] bg-white profile-info rounded-[10px] md:p-5 p-3 h-full">
                <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-5 mb-2.5">
                  <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                    Approval Number:
                  </p>
                  <p className="text-black font-medium md:text-base text-sm">
                    {showApNumber}
                  </p>
                </div>
                <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-5 mb-2.5">
                  <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                  hospital:
                  </p>
                  <p className="text-black font-medium md:text-base text-sm">
                    {showHospital?.[0]}
                  </p>
                </div>
                
                <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-5 mb-2.5">
                  <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                    Doctor:
                  </p>
                  <p className="text-black font-medium md:text-base text-sm">
                    {showDoctor}
                  </p>
                </div>
                <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-5 mb-2.5">
                  <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                    Program Coordinator:
                  </p>
                  <p className="text-black font-medium md:text-base text-sm">
                    {showProgram}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <TableLorder />
      )}

      <CustomModal
        heading="EMS Board Management"
        show={showModel}
        hide={() => {
          setShowModel(false);
          setEditGetData({});
          setFieldGroups("");
        }}
        fieldGroups={fieldGroups}
        onSubmitHndle={emsBoardManagementAddApi}
        loading={loading}
      />
    </>
  );
};

export default EmsBoardView;
