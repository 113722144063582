import React, { useEffect, useMemo, useState } from "react";

const SortingTable = (data, sortConfig,) => {
  

  const sortedData = useMemo(() => {
    if (sortConfig.key) {
      const sortedArray = [...data].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
      return sortedArray;
    }
    return data;
  }, [data, sortConfig]);

  

  return sortedData;
};

export default SortingTable;
