import React, { useState } from "react";
import ToastHandle from "../../../../helper/ToastMessage";
import { TableLorder } from "../../../../helper/Lorder";
import Pagination from "../../../../../Components/commonFunction/Pagination";
import SortingTable from "../../../../../Components/commonFunction/SortingTable";
import { FaSortDown, FaSortUp } from "react-icons/fa";

const EvaluationTable = ({
  data,
  setShowModel,
  tableLoading,
  currentPage,
  totalPages,
  handlePageChange,
}) => {
  const [evaluationData, setEvaluationData] = useState({ userCourseId: "" });
  const ids = evaluationData?.userCourseId
    ? JSON?.parse(evaluationData?.userCourseId)
    : {};
  const manageEvaluationHndle = () => {
    setShowModel({
      rangeData: ids,
      rangeOpen: true,
    });
  };
  // sorting
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };
  const sortedData = SortingTable(data, sortConfig);
  // sorting

  return (
    <>
      {!tableLoading ? (
        <div className="table-section table-responsive rounded-[15px] w-full bg-white">
          <table className="w-full h-full table-auto table-layout-fixed">
            <thead className="bg-[#d9d9d9] h-[60px]">
              <tr>
                <th
                  scope="col"
                  className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                >
                  Sr. No
                </th>
                <th
                  scope="col"
                  className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                  onClick={() => handleSort("name")}
                >
                  <p className="flex items-center gap-2 tableHeading">
                    <span>Name</span>
                    <span className="iconSort">
                      {sortConfig.direction === "asc" ? (
                        <FaSortUp />
                      ) : (
                        <FaSortDown />
                      )}
                    </span>
                  </p>
                </th>
                <th
                  scope="col"
                  className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                  onClick={() => handleSort("certificationNumber")}
                >
                  <p className="flex items-center gap-2 tableHeading">
                    <span>Certification number</span>
                    <span className="iconSort">
                      {sortConfig.direction === "asc" ? (
                        <FaSortUp />
                      ) : (
                        <FaSortDown />
                      )}
                    </span>
                  </p>
                </th>
                <th
                  scope="col"
                  className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                  onClick={() => handleSort("expirationDate")}
                >
                  <p className="flex items-center gap-2 tableHeading">
                    <span>Expiration date</span>
                    <span className="iconSort">
                      {sortConfig.direction === "asc" ? (
                        <FaSortUp />
                      ) : (
                        <FaSortDown />
                      )}
                    </span>
                  </p>
                </th>
                <th
                  scope="col"
                  className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                  onClick={() => handleSort("phoneNumber")}
                >
                  <p className="flex items-center gap-2 tableHeading">
                    <span>Phone Number</span>
                    <span className="iconSort">
                      {sortConfig.direction === "asc" ? (
                        <FaSortUp />
                      ) : (
                        <FaSortDown />
                      )}
                    </span>
                  </p>
                </th>
                <th
                  scope="col"
                  className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                >
                  Course
                </th>
                <th
                  scope="col"
                  className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedData?.map((evaluationItem, evaluationIndex) => {
                const {
                  certificationnumber,
                  course,
                  email,
                  expirationdate,
                  name,
                  phoneNumber,
                  _id,
                } = evaluationItem;
                return (
                  <tr className="h-[60px] border-b border-[#F4F5F9]">
                    <th
                      scope="row"
                      className="text-[#89868D] text-sm  font-normal px-5 py-3"
                    >
                      {evaluationIndex + 1}
                    </th>
                    <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                      {name}
                    </td>
                    <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                      {certificationnumber}
                    </td>
                    <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                      {expirationdate}
                    </td>
                    <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                      {phoneNumber}
                    </td>
                    <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => {
                          setEvaluationData({ userCourseId: e.target.value });
                        }}
                      >
                        <option value="" selected hidden>
                          Please Select
                        </option>
                        {course?.map((courseItem) => {
                          let idGet = {
                            courseId: courseItem?.courseId,
                            userId: _id,
                          };
                          return (
                            <option
                              value={JSON.stringify(idGet)}
                              selected={ids?.userId === _id ? true : false}
                            >
                              {courseItem?.courseName}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                    <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                      <button
                        className="text-sm text-white rounded-[5px] bg-[#01AF7B] py-1.5 px-2.5"
                        onClick={() => {
                          if (evaluationData.userCourseId !== "") {
                            if (ids?.userId === _id) {
                              manageEvaluationHndle();
                            } else {
                              ToastHandle(
                                "Please Select Correct Course",
                                "danger"
                              );
                            }
                          } else {
                            ToastHandle("Select Course", "danger");
                          }
                        }}
                      >
                        Manage Evaluations
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      ) : (
        <TableLorder />
      )}
    </>
  );
};

export default EvaluationTable;
