import React, { useEffect, useState } from "react";
import Table from "../../../../../../Components/Table";
import Studentimage from "../../../../../../images/student.png";
import { useParams } from "react-router-dom";
import { USERS_GET_AGENCY_DETAIL_BY_ID } from "../../../../../helper/ApiEndPoint";
import axiosInstance, { BASE_URL } from "../../../../../../Components/apiHeader/axiosInstance";
import StudentTable from "./StudentTable";
import ToastHandle from "../../../../../helper/ToastMessage";

const ViewStudentTable = () => {
  const { id: mainUrlId } = useParams();
  const [data, setData] = useState([]);
  const [emsGetDataByIdLoading, setEmsGetDataByIdLoading] = useState(false);
  const emsProfileDataGetApi = async (id) => {
    setEmsGetDataByIdLoading(true);
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}${USERS_GET_AGENCY_DETAIL_BY_ID}${id}`
      );
      if (response.status) {
        const agencyManagementGetData = response?.data?.detail;
        const convertData = [agencyManagementGetData]?.map(
          (agencyItem, agencyIndex) => {
            const {
              _id,
              name,
              address,
              phone,
              fire_chief_name,
              fire_chief_phone,
              fire_chief_email,
            } = agencyItem;
            return {
              _id,
              Sr: agencyIndex + 1,
              agencyname: name,
              address: address,
              phone: phone,
              chiefname: fire_chief_name,
              chiefphone: fire_chief_phone,
              chiefemail: fire_chief_email,
              student: null,
              agencyItem,
            };
          }
        );
        setData(convertData?.[0]);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setEmsGetDataByIdLoading(false);
  };
  useEffect(() => {
    if (mainUrlId) {
      emsProfileDataGetApi(mainUrlId);
    }
  }, [mainUrlId]);
  
  return <><StudentTable data={data?.agencyItem?.students} tableLoading={emsGetDataByIdLoading}/></>;
};

export default ViewStudentTable;
