import React, { useEffect, useState } from "react";
import Table from "../../../../../Components/Table";
import CustomModal from "../../../../../Components/customModal";
import axios from "axios";
import {
  COURSE_ADD_EVALUATION,
  COURSE_DELETE_EVALUATION,
  COURSE_EDIT_EVALUATION,
  COURSE_GET_EVALUATION,
  COURSE_LIST,
  EVALUATION_DELETE,
  INSTRUCTOR_COURSES_LIST,
  INSTRUCTOR_EVALUATION_GET,
  USERS_DELETE_USER,
} from "../../../../helper/ApiEndPoint";
import ToastHandle from "../../../../helper/ToastMessage";
import {
  authKey,
  createEditType,
  limitData,
  localStorageAuthUserData,
} from "../../../../helper/AuthUserData";
import axiosInstance, {
  BASE_URL,
} from "../../../../../Components/apiHeader/axiosInstance";
import SearchFilter from "../../../../../Components/commonFunction/SearchFilter";
import Pagination from "../../../../../Components/commonFunction/Pagination";
import EvaluationMainTable from "./evaluationMainTable/EvaluationMainTable";
import EvaluationQuestAddForm from "./evaluationMainTable/evaluationQuestionAddModel/EvaluationQuestAddForm";
import EvaluationQuestEditForm from "./evaluationMainTable/evaluationsQuestionEditModel/EvaluationQuestEditForm";
import { getEvaluationQuestionApi } from "../../../../helper/apis/evaluation/evaluationApis";

const EvaluationTable = ({
  createEvaluationOpent,
  creeateEvaluationClose,
  searchTerm,
}) => {
  const loginData = localStorageAuthUserData();
  const { detail } = loginData || {};
  const mainRole = detail?.role;
  const mainId = detail?._id;
  const [questionEditModel, setQuestionEditModel] = useState({
    type: false,
    editData: "",
  });
  const [EditGetData, setEditGetData] = useState("");
  const [tableLoading, setTableLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // table
  const columns = [
    {
      header: "Sr. No.",
      field: "Sr",
      render: (data) => (
        <div className="flex items-center py-3  gap-3">
          <p>{data}</p>
        </div>
      ),
    },
    { header: "Evaluation Name", field: "evaluationname" },
    { header: "Description:", field: "description" },
    { header: "Course", field: "course" },
  ];

  const [data, setData] = useState([]);
  const filterData = SearchFilter(data, searchTerm);
  const fetchDataQuestion = async () => {
    setTableLoading(true);
    try {
      const { response } = await getEvaluationQuestionApi();
      setData(response?.data);
      setCurrentPage(response?.data?.currentPage);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setTableLoading(false);
  };

  // table

  // input field

  const [courseList, setCourseList] = useState([]);
  const courseGetListApi = async () => {
    try {
      const response = await axiosInstance.get(
        mainRole === authKey?.instructor
          ? `${BASE_URL}${INSTRUCTOR_COURSES_LIST}${mainId}`
          : `${BASE_URL}${COURSE_LIST}`
      );
      if (response.status) {
        setCourseList(
          response?.data?.data?.map((itemss) => {
            if (itemss?.name !== undefined) {
              return itemss;
            }
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
  };

  const defaultInput = [
    {
      layout: "md:grid-cols-1 grid-cols-1", // Single column layout for this group
      fields: [
        {
          id: "address",
          name: "address",
          label: "Evaluation Title",
          type: "text",
          placeholder: "",
        },
      ],
    },
    {
      layout: "md:grid-cols-1 grid-cols-1", // Single column layout for this group
      fields: [
        {
          id: "description",
          name: "description",
          label: "Description",
          type: "textarea",
          placeholder: "",
        },
      ],
    },
    {
      layout: "md:grid-cols-1 grid-cols-1", // Single column layout for this group
      fields: [
        {
          id: "course",
          name: "course",
          label: "Course",
          type: "select",
          options: courseList,
        },
      ],
    },
  ];
  const [fieldGroups, setFieldGroups] = useState(defaultInput);
  const { _id, description, evaluationname, allCourse } = EditGetData;

  useEffect(() => {
    if (EditGetData !== "") {
      creeateEvaluationClose(true);
      setFieldGroups([
        {
          layout: "md:grid-cols-1 grid-cols-1", // Single column layout for this group
          fields: [
            {
              id: "address",
              name: "address",
              label: "Evaluation Title",
              type: "text",
              value: evaluationname || "",
              placeholder: "",
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1", // Single column layout for this group
          fields: [
            {
              id: "description",
              name: "description",
              label: "Description",
              type: "textarea",
              value: description || "",
              placeholder: "",
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1", // Single column layout for this group
          fields: [
            {
              id: "course",
              name: "course",
              label: "Course",
              type: "select",
              value: allCourse || "",
              options: courseList,
            },
          ],
        },
      ]);
    }
  }, [EditGetData]);

  // input field

  // evaluation create and edit
  const [loading, setLoading] = useState(false);
  const createEditEvaluationApiHndle = async (data, type, otherdata) => {
    setLoading(true);
    const { address, description } = data;
    const { selectedValues } = otherdata;
    const body = {
      createdBy: mainId,
      id: type === createEditType?.edit && _id,
      title: address,
      description: description,
      course:
        selectedValues?.length > 0
          ? selectedValues?.map((selet) => {
              return {
                course_id: selet?.value,
                name: selet?.name,
              };
            })
          : allCourse,
    };
    try {
      const response = await axiosInstance.post(
        `${BASE_URL}${
          type === createEditType?.edit
            ? COURSE_EDIT_EVALUATION
            : COURSE_ADD_EVALUATION
        }`,
        body
      );
      if (response.status) {
        // evaluationGetListApi();
        creeateEvaluationClose();
        ToastHandle(response?.data?.message, "success");
        setFieldGroups(defaultInput);
        setEditGetData("");
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setLoading(false);
  };
  // evaluation create and edit
  // delete evaluation api integration
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [DeleteModelClose, setDeleteModelClose] = useState(false);
  const evaluationDeleteApiHndle = async (id) => {
    try {
      setDeleteLoading(true);
      const response = await axiosInstance.delete(
        `${BASE_URL}${EVALUATION_DELETE}${id}`
      );
      if (response.status) {
        // evaluationGetListApi();
        fetchDataQuestion();
        setDeleteModelClose(true);
        setDeleteLoading(false);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
        setDeleteLoading(false);
      }
    }
  };
  // delete evaluation api integration

  // useEffect(() => {
  //   evaluationGetListApi();
  //   courseGetListApi();
  // }, [currentPage]);

  useEffect(() => {
    setFieldGroups(defaultInput);
  }, [courseList]);

  // ---
  useEffect(() => {
    fetchDataQuestion();
  }, []);

  return (
    <>
      <EvaluationMainTable
        columns={columns}
        data={filterData}
        tableLoading={tableLoading}
        iconsShow={true}
        modelModel={
          (data) => setQuestionEditModel({ type: true, editData: data })
          // setEditGetData(data)
        }
        deleteHndle={(id) => evaluationDeleteApiHndle(id)}
        deleteLoading={deleteLoading}
        DeleteModelClose={DeleteModelClose}
        setDeleteModelClose={setDeleteModelClose}
      />

      {filterData?.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      )}

      {/* <CustomModal
        heading="Create New Evaluation"
        show={createEvaluationOpent}
        hide={() => {
          creeateEvaluationClose(false);
          setEditGetData("");
          setFieldGroups(defaultInput);
        }}
        fieldGroups={fieldGroups}
        onSubmitHndle={(data, type, otherData) =>
          createEditEvaluationApiHndle(data, type, otherData)
        }
        loading={loading}
      /> */}
      <EvaluationQuestAddForm
        show={createEvaluationOpent}
        hide={() => creeateEvaluationClose(false)}
        updateAfterRes={() => {
          fetchDataQuestion();
        }}
      />
      <EvaluationQuestEditForm
        show={questionEditModel}
        hide={() => setQuestionEditModel({ type: false, editData: "" })}
        updateAfterRes={() => {
          fetchDataQuestion();
        }}
      />
    </>
  );
};

export default EvaluationTable;
