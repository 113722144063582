import React, { useState } from "react";
import FilterBar from "../../../Components/FilterBar";
import HospitalManagementTable from "./hospitalManagementTable/HospitalManagementTable";
import { authKey, localStorageAuthUserData } from "../../helper/AuthUserData";

const HospitalManagementIndex = () => {
  const AuthUserData = localStorageAuthUserData();
  const { detail } = AuthUserData || {};
  const { role } = detail || {};
  const roleFrontend = authKey?.admin;
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDropdownOption, setSelectedDropdownOption] = useState("");
  const [showModel, setShowModel] = useState({
    type: false,
  });
  const dropdownOptions = ["Ram", "Sham", "Ali", "John"];
  const handleSearch = (term) => {
    setSearchTerm(term);
  };
  const handleDropdownSelect = (option) => {
    setSelectedDropdownOption(option);
  };
  const DropdownName = "All Evaluation";
  return (
    <div className="student-managet overflow-y-auto">
      <FilterBar
        onSearch={handleSearch}
        dropdownOptions={dropdownOptions}
        onDropdownSelect={handleDropdownSelect}
        DropdownName={DropdownName}
        showDropdown={false}
        showBtn={true}
        showBtnText="Hospital Management"
        showModal={() => setShowModel({ type: true })}
        addButtonNotShow={role === roleFrontend}
      />
      <HospitalManagementTable
        hospitalManagementOpen={showModel}
        hospitalManagementCloseOpen={(type) => setShowModel(type)}
        searchTerm={searchTerm}
      />
    </div>
  );
};

export default HospitalManagementIndex;
