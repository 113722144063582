import axiosInstance, {
  BASE_URL,
} from "../../../../Components/apiHeader/axiosInstance";
import { USERS_INSTRUCTOR_LIST, USERS_UPDATE_USER } from "../../ApiEndPoint";
import ToastHandle from "../../ToastMessage";

export const instructorGetListApi = async (data) => {
  const { page, limit } = data || {};
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}${USERS_INSTRUCTOR_LIST}?page=${page}&limit=${limit}`
    );
    if (response.status) {
      const instructorGetData = response?.data?.detail;
      const convertData = instructorGetData?.map((instruct) => {
        const {
          _id,
          role,
          phone,
          fullname,
          expiry_date,
          email,
          certification_number,
          agency,
          address,
          is_verify,
          profilepic
        } = instruct;
        return {
          _id,
          role,
          agency,
          address,
          name: fullname,
          certificationNumber: certification_number,
          expirationDate: expiry_date,
          email: email,
          phoneNumber: phone,
          status: is_verify ? "verify" : "Not verify",
          profilepic
        };
      });
      return { convertData, response };
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};

export const editInstructorApiHndle = async (data, mainId) => {
  const {
    firstName,
    profilePicture,
    certificationNumber,
    expirationDate,
    emailInstructor,
    telephoneNumer,
    shortDescription,
    address,
  } = data;
  let formData = new FormData();
  formData.append("userId", mainId);
  formData.append("fullname", firstName);
  formData.append("email", emailInstructor);
  formData.append("certification_number", certificationNumber);
  formData.append("expiry_date", expirationDate);
  formData.append("phone", telephoneNumer);
  formData.append("address", address);
  formData.append("description", shortDescription);

  formData.append(
    "image",
    profilePicture[0] !== undefined ? profilePicture[0] : ""
  );
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}${USERS_UPDATE_USER}`,
      formData
    );
    const responseMessage = response?.data?.message;
    if (response.status) {
      ToastHandle(responseMessage, "success");
      return response;
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};
