import React, { useEffect, useState } from "react";
import Table from "../../../../Components/Table";
import Instructorimg from "../../../../images/instructor-profile.png";
import CustomModal from "../../../../Components/customModal";
import { useNavigate } from "react-router-dom";
import {
  USERS_DELETE_USER,
  USERS_INSTRUCTOR_LIST,
  USERS_UPDATE_USER,
  USERS_VERIFY_USER,
} from "../../../helper/ApiEndPoint";
import ToastHandle from "../../../helper/ToastMessage";
import axios from "axios";
import { createEditType, limitData } from "../../../helper/AuthUserData";
import axiosInstance, {
  BASE_URL,
} from "../../../../Components/apiHeader/axiosInstance";
import SearchFilter from "../../../../Components/commonFunction/SearchFilter";
import Pagination from "../../../../Components/commonFunction/Pagination";
import InstructorTable from "./InstructorTable";
import InstructorEditFrom from "./instructorEditModel/InstructorEditFrom";
import { instructorGetListApi } from "../../../helper/apis/instructor/instructorSection";

const InstructorManagementTable = ({ searchTerm }) => {
  const [showModal, setShowModal] = useState(false);
  const [instructorEditModel, setInstructorEditModel] = useState({
    type: false,
    editData: "",
  });

  const [editGetData, setEditGetData] = useState("");
  const navigate = useNavigate();
  // table
  const columns = [
    {
      header: "Name",
      field: "name",
      render: (data) => (
        <div className="flex items-center py-3 ps-3 gap-3">
          <img src={Instructorimg} alt="student" />
          <p>{data}</p>
        </div>
      ),
    },
    { header: "Certification Number", field: "certificationNumber" },
    { header: "Expiration Date", field: "expirationDate" },
    { header: "Email", field: "email" },
    { header: "Phone Number", field: "phoneNumber" },
    {
      header: "Status",
      field: "status",
      render: (status) => (
        <button className="text-[#CC4D1D] font-normal text-sm rounded-[5px] py-2 px-3 border border-[#CC4D1D]">
          {status}
        </button>
      ),
    },
  ];

  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const filterData = SearchFilter(data, searchTerm);
  const [instructorData, setInstructorData] = useState([]);
  const dataPagination = { page: currentPage, limit: limitData };
  const fetchData = async () => {
    setTableLoading(true);
    try {
      const { convertData, response } = await instructorGetListApi(
        dataPagination
      );
      setData(convertData);
      setCurrentPage(response?.data?.currentPage);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setTableLoading(false);
  };

  const [fieldGroups, setFieldGroups] = useState("");
  const {
    _id,
    name,
    email,
    expirationDate,
    phoneNumber,
    address,
    certificationNumber,
    lname,
    description,
    profileP,
  } = editGetData;
  useEffect(() => {
    if (editGetData !== "") {
      setShowModal(true);

      setFieldGroups([
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "fname",
              name: "fname",
              label: "First name",
              type: "text",
              value: name || "",
              placeholder: "",
            },
            {
              id: "profileP",
              name: "profileP",
              label: "profile picture",
              type: "file",
              value: "",
              placeholder: "",
            },
          ],
        },
        {
          layout: "md:grid-cols-2 grid-cols-1",
          fields: [
            {
              id: "cername",
              name: "cername",
              label: "Certification Number",
              type: "text",
              value: certificationNumber || "",
              placeholder: "",
            },
            {
              id: "exdate",
              name: "exdate",
              label: "Expiration Date",
              type: "date",
              value: expirationDate || "",
              placeholder: "",
            },
          ],
        },
        {
          layout: "md:grid-cols-2 grid-cols-1",
          fields: [
            {
              id: "email",
              name: "email",
              label: "E-Mail",
              type: "email",
              value: email || "",
              placeholder: "",
            },
            {
              id: "phone",
              name: "phone",
              label: "Telephone Number",
              type: "tel",
              value: phoneNumber || "",
              placeholder: "",
              pattern: "[0-9]{3}-[0-9]{2}-[0-9]{3}",
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "address",
              name: "address",
              label: "Address",
              type: "text",
              value: address || "",
              placeholder: "",
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "description",
              name: "description",
              label: "Short Description",
              type: "textarea",
              value: description || "",
              placeholder: "",
            },
          ],
        },
      ]);
    }
  }, [editGetData]);
  // input field

  // verify instructor api integration
  const instructorVerifyApiHndle = async (id) => {
    const body = {
      userId: id,
      status: true,
    };
    try {
      const response = await axiosInstance.post(
        `${BASE_URL}${USERS_VERIFY_USER}`,
        body
      );
      if (response.status) {
        fetchData();
        // instructorGetListApi(dataPagination);
        ToastHandle(response?.data?.message, "success");
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
  };

  // // instructor edit
  const [loading, setLoading] = useState(false);
  const editInstructorApiHndle = async (data, type) => {
    setLoading(true);
    const {
      address,
      cername,
      description,
      email,
      exdate,
      fname,
      lname,
      phone,
    } = data;
    const body = {
      userId: _id,
      fullname: fname,
      email: email,
      certification_number: cername,
      expiry_date: exdate,
      join_date: "2024-01-01",
      phone: phone,
      address: address,
      agency: "Agency Name",
      description: description,
    };
    try {
      const response = await axiosInstance.post(
        `${BASE_URL}${type === createEditType?.edit && USERS_UPDATE_USER}`,
        body
      );
      if (response.status) {
        // instructorGetListApi(dataPagination);
        fetchData();
        setShowModal(false);
        ToastHandle(response?.data?.message, "success");
        setFieldGroups("");
        setEditGetData("");
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setLoading(false);
  };
  // // evaluation create and edit

  // delete student api integration
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [DeleteModelClose, setDeleteModelClose] = useState(false);
  const instructorDeleteApiHndle = async (id) => {
    try {
      setDeleteLoading(true);
      const response = await axiosInstance.delete(
        `${BASE_URL}${USERS_DELETE_USER}${id}`
      );
      if (response.status) {
        // instructorGetListApi(dataPagination);
        fetchData();
        setDeleteModelClose(true);
        setDeleteLoading(false);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
        setDeleteLoading(false);
      }
    }
  };

  // delete student api integration
  // useEffect(() => {
  //   fetchData();
  //   // instructorGetListApi(dataPagination);
  // }, [currentPage]);
  useEffect(() => {
    fetchData();
  }, [currentPage]);

  return (
    <div>
      <InstructorTable
        columns={columns}
        data={filterData}
        modelModel={(data) => {
          setInstructorEditModel({ type: true, editData: data });
        }}
        navigateHndle={(id) => navigate(`/instructor-profile/${id}`)}
        verifyHndle={(id) => instructorVerifyApiHndle(id)}
        deleteHndle={(id) => instructorDeleteApiHndle(id)}
        deleteLoading={deleteLoading}
        DeleteModelClose={DeleteModelClose}
        setDeleteModelClose={setDeleteModelClose}
        tableLoading={tableLoading}
      />
      {filterData?.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      )}

      <CustomModal
        heading="Edit Instructor"
        show={showModal}
        hide={() => {
          setShowModal(false);
          setEditGetData("");
          setFieldGroups("");
        }}
        fieldGroups={fieldGroups}
        onSubmitHndle={(data, type) => editInstructorApiHndle(data, type)}
        loading={loading}
      />
      {/* // new model */}
      <InstructorEditFrom
        show={instructorEditModel}
        hide={() => setInstructorEditModel({ type: false })}
        updateAfterRes={() => fetchData()}
      />
    </div>
  );
};

export default InstructorManagementTable;
