import React from "react";
import Profileimg from "../../../images/profile-img.png";
import { localStorageAuthUserData } from "../../helper/AuthUserData";

const AdminProfileIndex = ({ profileUpdateData }) => {
  const adminProfileData = localStorageAuthUserData();
  const { detail } = adminProfileData;

  const {
    fullname,
    email,
    profilepic,
    description,
    address,
    phone,
    certification_number,
    lastname
  } = profileUpdateData ? profileUpdateData : detail || {};
  console.log(profileUpdateData, detail,'detaildetail');
  return (
    <div className="profile-section overflow-y-auto">
      <div className="flex gap-3 md:gap-5 2xl:gap-8 flex-wrap md:items-start 2xl:items-center">
        <div className="profile-img rounded-[10px] md:px-5 px-3s py-10 bg-section w-full md:w-[30%] lg:w-[30%] 2xl:w-[20%] ">
          <div className="mb-3">
            <img
              className="text-center mx-auto border-2 border-write-500 h-[120px] w-[120px] rounded-full"
              src={profilepic ? profilepic : Profileimg}
            />
          </div>
          <h3 className="text-center text-xl font-semibold text-black">
            {fullname}
          </h3>
        </div>
        <div className="w-full md:w-[67%] lg:w-[65%] 2xl:w-[77%] bg-white profile-info rounded-[10px] md:p-5 p-3">
          <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-4 mb-2.5">
            <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
              Name:
            </p>
            <p className="text-black font-medium md:text-base text-sm">
              {fullname}
            </p>
          </div>
          <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-4 mb-2.5">
            <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
            Last Name:
            </p>
            <p className="text-black font-medium md:text-base text-sm">
              {lastname}
            </p>
          </div>
          <div className="flex flex-wrap md:mb-4 mb-2.5 sm:gap-5 gap-3">
            <p className="text-[#89868D] font-medium md:text-base text-sm  md:w-[40%] xl:w-[20%]">
              Certification Number:
            </p>
            <p className="text-black font-medium md:text-base text-sm">
              {certification_number}
            </p>
          </div>

          <div className="flex flex-wrap md:mb-4 mb-2.5 sm:gap-5 gap-3">
            <p className="text-[#89868D] font-medium md:text-base text-sm  md:w-[40%] xl:w-[20%]">
              E-mail:
            </p>
            <p className="text-black font-medium md:text-base text-sm">
              {email}
            </p>
          </div>
          <div className="flex flex-wrap md:mb-4 mb-2.5 sm:gap-5 gap-3">
            <p className="text-[#89868D] font-medium md:text-base text-sm  md:w-[40%] xl:w-[20%]">
              Address:
            </p>
            <p className="text-black font-medium md:text-base text-sm">
              {address}
            </p>
          </div>
          <div className="flex flex-wrap md:mb-4 mb-2.5 sm:gap-5 gap-3">
            <p className="text-[#89868D] font-medium md:text-base text-sm  md:w-[40%] xl:w-[20%]">
              Phone:
            </p>
            <p className="text-black font-medium md:text-base text-sm">
              {phone}
            </p>
          </div>
        </div>
      </div>

      <div className="profile-des bg-white profile-info rounded-[10px] md:p-8 p-3 md:mt-10 mt-5">
        <h3 className="text-black font-medium md:text-base text-sm mb-2">
          About me
        </h3>
        <p className="text-[#89868D] font-normal text-sm leading-6">
          {description}
        </p>
      </div>
    </div>
  );
};

export default AdminProfileIndex;
