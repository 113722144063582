import axiosInstance, {
  BASE_URL,
} from "../../../../Components/apiHeader/axiosInstance";
import {
    EVALUATION_ASSIGN_COURSE_QUESTION,
  EVALUATION_GET_ALL_QUESTIONS,
  EVALUATION_QUESTIONS_CREATE,
  EVALUATION_QUESTIONS_UPDATE,
} from "../../ApiEndPoint";
import ToastHandle from "../../ToastMessage";

export const addEvaluationQuestionApi = async (data, selectedOptions) => {
  //   student,
  const { questionN } = data;
  let body = {
    title: questionN,
  };
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}${EVALUATION_QUESTIONS_CREATE}`,
      body
    );
    const responseMessage = response?.data?.message;
    if (response.status) {
      ToastHandle(responseMessage, "success");
      return response;
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};

export const getEvaluationQuestionApi = async () => {
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}${EVALUATION_GET_ALL_QUESTIONS}`
    );
    if (response.status) {
      return { response };
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};

export const updateEvaluationQuestionApi = async (data) => {
  //   student,
  const { questionN, editUserId } = data;
  let body = {
    title: questionN,
  };
  try {
    const response = await axiosInstance.put(
      `${BASE_URL}${EVALUATION_QUESTIONS_UPDATE}${editUserId}`,
      body
    );
    const responseMessage = response?.data?.message;
    if (response.status) {
      ToastHandle(responseMessage, "success");
      return response;
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};

export const assignCourseStudentEvaluationQuestionApi = async (data) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}${EVALUATION_ASSIGN_COURSE_QUESTION}`,
      data
    );
    const responseMessage = response?.data?.message;
    if (response.status) {
      ToastHandle(responseMessage, "success");
      return response;
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};
