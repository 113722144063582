import React, { useEffect, useState } from "react";
import DeleteModel from "../../../../../helper/DeleteModel";
import { TableLorder } from "../../../../../helper/Lorder";
import { PiTrashBold } from "react-icons/pi";
import { FiEdit } from "react-icons/fi";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { DataEmpty } from "../../../../../../Components/commonFunction/CommonFunction";

const StudentTable = ({
  data,
  manageButton = false,
  iconsShow = false,
  modelModel = () => {},
  showModal = () => {},
  navigateHndle = () => {},
  otherAction,
  noAction = false,
  verifyHndle = () => {},
  deleteHndle,
  deleteLoading,
  DeleteModelClose,
  setDeleteModelClose,
  tableLoading = false,
  notShowing = false,
  editButtonNotShowing = false,
}) => {
  const navigate = useNavigate();
  const [deleteModel, setDeleteModel] = useState({
    deleteShowType: false,
    deleteId: "",
    deleteItem: "",
  });

  const closeDeleteModel = () => {
    setDeleteModel({
      deleteShowType: false,
      deleteId: "",
      deleteItem: "",
    });
    setDeleteModelClose(false);
  };

  useEffect(() => {
    if (DeleteModelClose) {
      closeDeleteModel();
    }
  }, [DeleteModelClose]);
  return (
    <div className="table-section table-responsive rounded-[15px] w-full bg-white">
      {!tableLoading ? (
        <>
          {data?.length > 0 ? (
            <table className="w-full h-full table-auto table-layout-fixed">
              <thead className="bg-[#d9d9d9] h-[60px]">
                <tr>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                  >
                    Certification number{" "}
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                  >
                    Expiration date
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                  >
                    Phone Number
                  </th>
                  {/* <th
              scope="col"
              className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
            >
              Status
            </th> */}
                  {/* <th
              scope="col"
              className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
            >
              Action
            </th> */}
                </tr>
              </thead>
              <tbody>
                {data?.map((studentItem) => {
                  const {
                    fullname,
                    certification_number,
                    expiry_date,
                    email,
                    phone,
                    is_verify,
                  } = studentItem;

                  return (
                    <tr className=" h-[60px] border-b border-[#F4F5F9]">
                      <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                        <div className="flex ">
                          <div className="mt-2 ms-3">{fullname}</div>
                        </div>
                      </td>
                      <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                        {certification_number}
                      </td>
                      <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                        {expiry_date}
                      </td>
                      <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                        {email}
                      </td>
                      <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                        {phone}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <DataEmpty />
          )}
        </>
      ) : (
        <TableLorder />
      )}
      <DeleteModel
        show={deleteModel?.deleteShowType}
        hide={() =>
          setDeleteModel({
            deleteShowType: false,
            deleteId: "",
          })
        }
        deleteItem={deleteModel?.deleteItem}
        confimDelete={() => deleteHndle(deleteModel?.deleteId)}
        deleteLoading={deleteLoading}
      />
    </div>
  );
};

export default StudentTable;
