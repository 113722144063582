import React, { useEffect, useState } from "react";
import { TableLorder } from "../../../admin/helper/Lorder";
import { DataEmpty } from "../../../Components/commonFunction/CommonFunction";
import { FaSortDown, FaSortUp } from "react-icons/fa6";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { PiTrashBold } from "react-icons/pi";
import { studentEvaluationQuestionGetApi } from "../../../admin/helper/apis/student/StudentApis";
import { localStorageAuthUserData } from "../../../admin/helper/AuthUserData";
import SearchFilter from "../../../Components/commonFunction/SearchFilter";

const EvaluationTable = () => {
  const loginData = localStorageAuthUserData();
  const { detail } = loginData || {};
  console.log(loginData, "loginDataloginDataloginData");
  const mainRole = detail?.role;
  const mainId = detail?._id;
  const [data, setData] = useState([]);
  let filterData = SearchFilter(data, "");

  const fetechStudentQuestionData = async () => {
    try {
      const { response } = await studentEvaluationQuestionGetApi(mainId);
      setData(response?.data?.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (mainId) {
      fetechStudentQuestionData();
    }
  }, [mainId]);

  console.log(data, "datadata");
  return (
    <div className="table-section table-responsive rounded-[15px] w-full bg-white">
      {true ? (
        <>
          {[1] > 0 ? (
            <table className="w-full h-full table-auto table-layout-fixed">
              <thead className="bg-[#d9d9d9] h-[60px]">
                <tr>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                  >
                    Sr. No.
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    // onClick={() => handleSort("Name")}
                  >
                    <p className="flex items-center gap-2 tableHeading">
                      <span>Name of Agency</span>
                      <span className="iconSort">
                        {/* {sortConfig.direction === "asc" ? (
                      <FaSortUp />
                    ) : (
                      <FaSortDown />
                    )} */}
                      </span>
                    </p>
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    // onClick={() => handleSort("address")}
                  >
                    <p className="flex items-center gap-2 tableHeading">
                      <span>Address</span>
                      <span className="iconSort">
                        {/* {sortConfig.direction === "asc" ? (
                      <FaSortUp />
                    ) : (
                      <FaSortDown />
                    )} */}
                      </span>
                    </p>
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    // onClick={() => handleSort("phone")}
                  >
                    <p className="flex items-center gap-2 tableHeading">
                      <span>Phone</span>
                      <span className="iconSort">
                        {/* {sortConfig.direction === "asc" ? (
                      <FaSortUp />
                    ) : (
                      <FaSortDown />
                    )} */}
                      </span>
                    </p>
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    // onClick={() => handleSort("fireChiefName")}
                  >
                    <p className="flex items-center gap-2 tableHeading">
                      <span>Fire Chief Name</span>
                      <span className="iconSort">
                        {/* {sortConfig.direction === "asc" ? (
                      <FaSortUp />
                    ) : (
                      <FaSortDown />
                    )} */}
                      </span>
                    </p>
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    // onClick={() => handleSort("fireChiefName")}
                  >
                    Fire Chief Phone
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                  >
                    Fire Chief Email
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {filterData?.map((questionItem, questionIndex) => {
                  const { courseId, evaluations } = questionItem;

                  return (
                    <tr className=" h-[60px] border-b border-[#F4F5F9]">
                      <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                        {questionIndex + 1}
                      </td>
                      <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                        fdfd
                      </td>

                      <td>
                        <div className="flex items-center gap-3">
                          <button
                            className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                            // onClick={() => navigateHndle(_id)}
                          >
                            <MdOutlineRemoveRedEye className="text-[#CC4D1D]" />
                          </button>
                          <>
                            <button
                              // onClick={() => modelModel(agenctyItem)}
                              className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                            >
                              <FiEdit className="text-[#CC4D1D]" />
                            </button>
                            <button
                              // onClick={() =>
                              //   setDeleteModel({
                              //     deleteShowType: true,
                              //     deleteId: _id,
                              //   })
                              // }
                              className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                            >
                              <PiTrashBold className="text-[#CC4D1D]" />
                            </button>
                          </>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <DataEmpty />
          )}
        </>
      ) : (
        <TableLorder />
      )}
    </div>
  );
};

export default EvaluationTable;
