
import React, { useEffect, useState } from "react";
import "./customModal.css";
import { DataEmpty } from "../commonFunction/CommonFunction";
import axios from "axios";
import axiosInstance, { BASE_URL } from "../apiHeader/axiosInstance";
import ToastHandle from "../../admin/helper/ToastMessage";

const RangeSlider = ({
  show,
  hide,
  heading,
  showModel,
  rangeSectionData,
  apiDataSend,
}) => {
  const [values, setValues] = useState([]);

  const handleSliderChange = (score, mainId, evaluationName) => {
    setValues((prevValues) => {
      const existingEntryIndex = prevValues.findIndex(
        (entry) => entry.evaluation_name === evaluationName
      );

      if (existingEntryIndex !== -1) {
        const updatedValues = [...prevValues];
        updatedValues[existingEntryIndex] = {
          ...updatedValues[existingEntryIndex],
          score: score,
        };
        return updatedValues;
      } else {
        const newEntry = {
          evaluation_id: mainId,
          evaluation_name: evaluationName,
          score: score,
        };
        return [...prevValues, newEntry];
      }
    });
  };

  const saveHandle = () => {
    apiDataSend(values);
    console.log(values, "valuesvalues");
  };

  const evaluationListGetInintialData = async (ID) => {
    try {
      if (!ID) return;
      const response = await axios.get(`${BASE_URL}/courseEvolution/get/${ID}`);
      if (response.status) {
        let newArr = [];
        for (let i = 0; i < response.data.data.evolutions.length; i++) {
          const element = response.data.data.evolutions[i];
          if (element.courseID == showModel?.rangeData.courseId) {
            let obj = element.courseEvolution;
            for (let key in obj) {
              newArr.push({
                evaluation_id: "",
                evaluation_name: key,
                score: obj[key],
              });
            }
          }
        }
        if (newArr.length > 0) {
          setValues(newArr);
        }
      }
    } catch (error) {
      setValues([]);
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
  };

  useEffect(() => {
    evaluationListGetInintialData(showModel?.rangeData?.userId);
  }, [showModel]);

  function getScoreByEvaluationName(data, evaluationName) {
    const evaluation = data.find(
      (item) => item?.evaluation_name === evaluationName
    );
    return evaluation ? evaluation.score : 0;
  }

  return (
    <>
      {show ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 w-full max-w-[500px]  popup">
              {/* content */}
              <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/* header */}
                <div className="flex items-center justify-between p-3 border-b border-solid border-[#EEEEEE] rounded-t">
                  <h3 className="text-lg font-medium text-black">{heading}</h3>
                  <button
                    className="ml-auto bg-transparent border-0 text-black float-right text-3xl font-normal outline-none focus:outline-none pb-2"
                    onClick={hide}
                  >
                    <span className="bg-transparent text-[#9E9E9E] h-6 w-6 text-3xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>

                {/* body */}
                {rangeSectionData?.length > 0 ? (
                  <div className="relative px-6 flex-auto">
                    <div className="form">
                      <div>
                        {rangeSectionData?.map((range, rangeIndex) => {
                          const { _id, title } = range;
                          return (
                            <div key={rangeIndex} className="mb-4">
                              <div className="mb-2 text-lg">{title}</div>
                              <div className="flex justify-between gap-2"
                              >
                                {/* Rating buttons */}
                                {["Poor", "1", "2", "3", "4", "Excellent"].map(
                                  (label, index) => (
                                    <button
                                      key={index}
                                      onClick={() =>
                                        handleSliderChange(
                                          index + 1,
                                          _id,
                                          title
                                        )
                                      }
                                      className={`rating-button ${
                                        getScoreByEvaluationName(
                                          values,
                                          title
                                        ) ===
                                        index + 1
                                          ? "selected"
                                          : ""
                                      }`}
                                    >
                                      {label}
                                    </button>
                                  )
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <DataEmpty />
                )}

                {/* footer */}
                <div className="flex items-center justify-end px-6 py-3 border-t border-solid border-[#EEEEEE] rounded-b gap-3">
                  <button
                    className="text-white bg-[#CCCCCC] font-medium px-10 py-3.5 text-sm outline-none rounded-[5px] focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={hide}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-[#CC4D1D] text-white active:bg-emerald-600 font-medium text-sm px-10 py-3.5 rounded-[5px] shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={saveHandle}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default RangeSlider;
