import React, { useEffect, useState } from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { PiTrashBold } from "react-icons/pi";
import DeleteModel from "../../../../../helper/DeleteModel";
import EditBatchForm from "../editBatchModel/EditBatchForm";
import { useNavigate } from "react-router-dom";
import SearchFilter from "../../../../../../Components/commonFunction/SearchFilter";
import { DataEmpty } from "../../../../../../Components/commonFunction/CommonFunction";
import { formatDateConvert } from "../../../../../helper/dateFormate";
import { TableLorder } from "../../../../../helper/Lorder";

const StudentBacheTable = ({
  bacheStudentData,
  deleteHndle,
  deleteLoading,
  DeleteModelClose,
  setDeleteModelClose,
  updateAfterRes,
  tableLoading,
}) => {
  const navigate = useNavigate();
  // const [editBatchModel, setEditBatchModel] = useState({
  //   type: false,
  // });
  const [deleteModel, setDeleteModel] = useState({
    deleteShowType: false,
    deleteId: "",
    deleteItem: "",
  });

  const closeDeleteModel = () => {
    setDeleteModel({
      deleteShowType: false,
      deleteId: "",
      deleteItem: "",
    });
    setDeleteModelClose(false);
  };

  useEffect(() => {
    if (DeleteModelClose) {
      closeDeleteModel();
    }
  }, [DeleteModelClose]);

  return (
    <div className="table-section table-responsive rounded-[15px] w-full bg-white mt-3">
      {!tableLoading ? (
        <>
          {bacheStudentData?.length > 0 ? (
            <table className="w-full h-full table-auto table-layout-fixed">
              <thead className="bg-[#d9d9d9] h-[60px]">
                <tr>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left p-3"
                  >
                    Sr. No.
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left p-3"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left p-3"
                  >
                    Certification Number
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left p-3"
                  >
                    Start Date
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left p-3"
                  >
                    End Date
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left p-3"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left p-3"
                  >
                    Phone Number
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left p-3"
                  >
                    Batch
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left p-3"
                  >
                    Agency Name
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left p-3"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {bacheStudentData?.map((batchStudentItem, batchIndex) => {
                  const {
                    _id,
                    fullname,
                    certification_number,
                    expiry_date,
                    email,
                    phone,
                    sectionName,
                    sectionId,
                    agency,
                    startDate,
                    endDate,
                  } = batchStudentItem;

                  return (
                    <tr className=" h-[60px] border-b border-[#F4F5F9]">
                      <td className="text-[#89868D] text-sm  font-normal p-3">
                        {batchIndex + 1}
                      </td>
                      <td className="text-[#89868D] text-sm  font-normal p-3">
                        {fullname}
                      </td>
                      <td className="text-[#89868D] text-sm  font-normal p-3">
                        {certification_number}
                      </td>
                      <td className="text-[#89868D] text-sm  font-normal  p-3">
                        {startDate ? formatDateConvert(startDate) : "null"}
                      </td>
                      <td className="text-[#89868D] text-sm  font-normal  p-3">
                        {endDate ? formatDateConvert(endDate) : "null"}{" "}
                      </td>
                      <td className="text-[#89868D] text-sm  font-normal p-3">
                        {email}
                      </td>
                      <td className="text-[#89868D] text-sm  font-normal p-3">
                        {phone}
                      </td>
                      <td className="text-[#89868D] text-sm  font-normal p-3">
                        {sectionName}
                      </td>
                      <td className="text-[#89868D] text-sm  font-normal p-3">
                        {agency?.name}
                      </td>
                      <td>
                        <div className="flex items-center gap-3">
                          <button
                            className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                            onClick={(id) =>
                              navigate(`/Student-profile/${_id}`)
                            }
                          >
                            <MdOutlineRemoveRedEye className="text-[#CC4D1D]" />
                          </button>
                          {/* {sectionName !== null && (
                        <button
                          onClick={() =>
                            setEditBatchModel({
                              type: true,
                              editData: batchStudentItem,
                              studentsAll: bacheStudentData,
                            })
                          }
                          className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                        >
                          <FiEdit className="text-[#CC4D1D]" />
                        </button>
                      )}

                      <button
                      onClick={() =>
                        setDeleteModel({
                          deleteShowType: true,
                          deleteId: _id,
                          //   deleteItem: name,
                        })
                      }
                      className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                    >
                      <PiTrashBold className="text-[#CC4D1D]" />
                    </button> */}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <DataEmpty />
          )}
        </>
      ) : (
        <TableLorder />
      )}

      <DeleteModel
        show={deleteModel?.deleteShowType}
        hide={() =>
          setDeleteModel({
            deleteShowType: false,
            deleteId: "",
          })
        }
        deleteItem={deleteModel?.deleteItem}
        confimDelete={() => deleteHndle(deleteModel?.deleteId)}
        deleteLoading={deleteLoading}
      />
      {/* <EditBatchForm
        show={editBatchModel}
        hide={() => {
          setEditBatchModel({ type: false });
        }}
        updateAfterRes={updateAfterRes}
      /> */}
    </div>
  );
};

export default StudentBacheTable;
