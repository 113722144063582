import React, { useEffect, useState } from "react";
import { updateHospitalData } from "../../../../helper/apis/apis";
import { useForm } from "react-hook-form";
import {
  errorEndPoint,
  errorMessageShow,
} from "../../../../helper/ErrorMessage";
import { LoaderButton } from "../../../../../Components/commonFunction/Loader";
import { editInstructorApiHndle } from "../../../../helper/apis/instructor/instructorSection";

const InstructorEditFrom = ({ show, hide, updateAfterRes }) => {
  const { editData } = show;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [formLoading, setFormLoading] = useState(false);
  const onSubmit = async (data) => {
    setFormLoading(true);
    const res = await editInstructorApiHndle(data, editData?._id);
    if (res.status) {
      hide();
      updateAfterRes();
    }
    setFormLoading(false);
  };

  useEffect(() => {
    if (editData) {
      const { name, certificationNumber, expirationDate, email, phoneNumber } =
        editData;
      reset({
        firstName: name,
        profilePicture: "",
        certificationNumber: certificationNumber,
        expirationDate: expirationDate,
        emailInstructor: email,
        address:"",
        telephoneNumer: phoneNumber,
        shortDescription: "",
      });
    }
  }, [editData]);
  return (
    <>
      {show?.type ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 lg:w-[60%] w-[90%]  popup">
              {/* content */}
              <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/* header */}
                <div className="flex items-center justify-between p-3 border-b border-solid border-[#EEEEEE] rounded-t">
                  <h3 className="text-lg font-medium text-black">
                    Edit Instructor
                  </h3>
                  <button
                    className="ml-auto bg-transparent border-0 text-black float-right text-3xl font-normal outline-none focus:outline-none pb-2"
                    onClick={() => hide()}
                  >
                    <span className="bg-transparent text-[#9E9E9E] h-6 w-6 text-3xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/* body */}
                <div className="relative p-6 flex-auto">
                  <div className="form">{/* dynamic content */}</div>
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
                    <div className="">
                      <label className="block mb-2 text-sm font-normal text-[#727272] ">
                        First name
                      </label>
                      <input
                        type="text"
                        {...register("firstName", {
                          required: true,
                        })}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                      {errors.firstName && (
                        <>
                          {errorMessageShow(
                            errorEndPoint?.THIS_FIELD_IS_REQUIRED
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-normal text-[#727272]">
                        Profile Picture
                      </label>
                      <input
                        type="file"
                        {...register("profilePicture", {})}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                    </div>
                  </div>
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-5 my-3">
                    <div className="">
                      <label className="block mb-2 text-sm font-normal text-[#727272] ">
                        Certification Number
                      </label>
                      <input
                        type="text"
                        {...register("certificationNumber", {
                          required: true,
                        })}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                      {errors.certificationNumber && (
                        <>
                          {errorMessageShow(
                            errorEndPoint?.THIS_FIELD_IS_REQUIRED
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-normal text-[#727272]">
                        Expiration Date
                      </label>
                      <input
                        type="date"
                        {...register("expirationDate", {
                          required: true,
                        })}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                      {errors.expirationDate && (
                        <>
                          {errorMessageShow(
                            errorEndPoint?.THIS_FIELD_IS_REQUIRED
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
                    <div className="">
                      <label className="block mb-2 text-sm font-normal text-[#727272] ">
                        E-Mail
                      </label>
                      <input
                        type="text"
                        {...register("emailInstructor", {
                          required: true,
                        })}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                      {errors.emailInstructor && (
                        <>
                          {errorMessageShow(
                            errorEndPoint?.THIS_FIELD_IS_REQUIRED
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-normal text-[#727272]">
                        Telephone Number
                      </label>
                      <input
                        type="text"
                        {...register("telephoneNumer")}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                    </div>
                  </div>
                  <div className="my-3">
                    <label className="block mb-2 text-sm font-normal text-[#727272]">
                      Address
                    </label>
                    <input
                      type="text"
                      {...register("address")}
                      className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-normal text-[#727272] my-3">
                      Short Description
                    </label>
                    <textarea
                      type="text"
                      {...register("shortDescription")}
                      className="border border-[#D9D9D9] p-3 h-[100px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                  </div>
                </div>
                {/* footer */}
                <div className="flex items-center justify-end px-6 py-4 border-t border-solid border-[#EEEEEE] rounded-b gap-5">
                  <button
                    className="text-white bg-[#CCCCCC] font-medium px-10 py-3.5 text-sm outline-none rounded-[5px] focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => hide()}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-[#CC4D1D] text-white active:bg-emerald-600 font-medium text-sm px-10 py-3.5 rounded-[5px] shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    // onClick={handleSubmit(onSubmit)}
                    onClick={handleSubmit(
                      (data) => {
                        onSubmit(data);
                      },
                      (err) => {
                        console.log(err, "ee");
                      }
                    )}
                  >
                    {!formLoading ? "Add" : <LoaderButton />}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default InstructorEditFrom;
