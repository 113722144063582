import React, { useEffect, useState } from "react";
import Table from "../../../../Components/Table";
import {
  USERS_DELETE_HOSPITAL,
  USERS_GET_HOSPITAL,
} from "../../../helper/ApiEndPoint";
import ToastHandle from "../../../helper/ToastMessage";
import {
  limitData,
} from "../../../helper/AuthUserData";
import axiosInstance, {
  BASE_URL,
} from "../../../../Components/apiHeader/axiosInstance";
import {
  imageFrame,
  profileInterFaceType,
} from "../../../../Components/commonFunction/CommonFunction";
import { useNavigate } from "react-router-dom";
import SearchFilter from "../../../../Components/commonFunction/SearchFilter";
import Pagination from "../../../../Components/commonFunction/Pagination";
import HopitalAddModel from "./hopitalAddModel/HopitalAddModel";
import HospitalEditForm from "./hospitalEditModel/HospitalEditForm";
import { instructorGetListApi } from "../../../helper/apis/instructor/instructorSection";

const HospitalManagementTable = ({
  hospitalManagementOpen,
  hospitalManagementCloseOpen,
  searchTerm,
}) => {
  const [hospitalModelEditShow, setHospitalModelEditShow] = useState({
    type: false,
    editData: "",
  });
  const navigate = useNavigate();

  // table
  const columns = [
    {
      header: "Sr. No.",
      field: "Sr",
      render: (data) => (
        <div className="flex items-center py-3  gap-3">
          <p>{data}</p>
        </div>
      ),
    },
    { header: "Hospital Name", field: "hospitalname" },
    { header: "Location:", field: "location" },
    { header: "EMS Coordinator", field: "ems" },
    {
      header: "Medical Control",
      field: "medicalcontrol",
      render: (status) => (
        <button className="text-[#CC4D1D] font-normal text-sm rounded-[5px] py-2 px-3 border border-[#CC4D1D]">
          {status}
        </button>
      ),
    },
    {
      header: "Hospital Image",
      field: "hospitalimage",
      render: (data) => {
        return imageFrame(data);
      },
    },
  ];
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const filterData = SearchFilter(data, searchTerm);
  const hospitalManagementGetListApi = async () => {
    setTableLoading(true);
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}${USERS_GET_HOSPITAL}?page=${currentPage}&limit=${limitData}`
      );
      if (response.status) {
        const hospitalData = response?.data?.detail;
        const convertData = hospitalData?.map((hopital, hopitalIndex) => {
          const {
            _id,
            name,
            location,
            ems_coordinator,
            medical_control,
            image,
          } = hopital;
          return {
            Sr: hopitalIndex + 1,
            hospitalname: name,
            location: location,
            emsRow: ems_coordinator,
            ems: ems_coordinator?.fullname,
            medicalRow: medical_control,
            medicalcontrol: medical_control?.fullname,
            hospitalimage: image[0],
            _id,
          };
        });
        setData(convertData);
        setCurrentPage(response?.data?.currentPage);
        setTotalPages(response?.data?.totalPages);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setTableLoading(false);
  };

  // table

  // delete course api integration
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [DeleteModelClose, setDeleteModelClose] = useState(false);
  const hospitalDeleteApiHndle = async (id) => {
    try {
      setDeleteLoading(true);
      const response = await axiosInstance.delete(
        `${BASE_URL}${USERS_DELETE_HOSPITAL}${id}`
      );
      if (response.status) {
        hospitalManagementGetListApi();
        setDeleteModelClose(true);
        setDeleteLoading(false);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
        setDeleteLoading(false);
      }
    }
  };
  // delete course api integration

  useEffect(() => {
    hospitalManagementGetListApi();
  }, [currentPage]);

  useEffect(() => {
    instructorGetListApi();
  }, []);

  return (
    <>
      <Table
        columns={columns}
        data={filterData}
        modelModel={(data) => {
          setHospitalModelEditShow({ type: true, editData: data });
        }}
        navigateHndle={(id) => {
          let object = { id, type: profileInterFaceType?.hospitalM };
          navigate(`/hospital-profile/${JSON.stringify(object)}`);
        }}
        deleteHndle={(id) => hospitalDeleteApiHndle(id)}
        deleteLoading={deleteLoading}
        DeleteModelClose={DeleteModelClose}
        setDeleteModelClose={setDeleteModelClose}
        tableLoading={tableLoading}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
      <HopitalAddModel
        show={hospitalManagementOpen}
        hide={(type) => {
          hospitalManagementCloseOpen({ type: type });
        }}
        updateAfterRes={() => hospitalManagementGetListApi()}
      />
      <HospitalEditForm
        show={hospitalModelEditShow}
        hide={() => setHospitalModelEditShow({ type: false })}
        updateAfterRes={() => hospitalManagementGetListApi()}
      />
    </>
  );
};

export default HospitalManagementTable;
