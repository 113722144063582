import React, { useEffect, useState } from "react";
import CourseImg from "../../../../images/course.png";
import { FaEdit, FaRegUserCircle } from "react-icons/fa";
import { TbClock24 } from "react-icons/tb";
import { Link } from "react-router-dom";
import { TableLorder } from "../../../../admin/helper/Lorder";
import { DataEmpty } from "../../../../Components/commonFunction/CommonFunction";
import { MdDelete } from "react-icons/md";
import DeleteModel from "../../../../admin/helper/DeleteModel";

const CourseManagementList = ({
  data,
  deleteHndle,
  deleteLoading,
  DeleteModelClose,
  setDeleteModelClose,
  tableLoading = false,
  modelModel = () => {},
}) => {
  // ----

  const [deleteModel, setDeleteModel] = useState({
    deleteShowType: false,
    deleteId: "",
    deleteItem: "",
  });

  const closeDeleteModel = () => {
    setDeleteModel({
      deleteShowType: false,
      deleteId: "",
      deleteItem: "",
    });
    setDeleteModelClose(false);
  };

  useEffect(() => {
    if (DeleteModelClose) {
      closeDeleteModel();
    }
  }, [DeleteModelClose]);
  return (
    <>
      {!tableLoading ? (
        <>
          {data?.length > 0 ? (
            <div className="course-mangement-inner grid md:grid-cols-3 grid-cols-1 xl:gap-8 gap-3">
              {data?.map((courseItem) => {
                const instructorCourse = courseItem;
                const { _id, name, monthly_price, totalHours, numbers } =
                  instructorCourse || {};
                return (
                  <div className="bg-white  rounded-[10px]">
                    <img className="w-full" src={CourseImg} />
                    <div className="p-3 2xl:px-5 xl:px-3">
                      <h3 className="text-lg font-medium text-black mb-2">
                        {name}
                      </h3>
                      <div className="flex flex-wrap items-center 2xl:gap-5 xl:gap-3 gap-4 xl:py-5 py-2">
                        <div className="flex items-center 2xl:gap-2 xl:gap-1 gap-2">
                          <FaRegUserCircle className="text-[#89868D]" />
                          <p className="text-[#89868D] font-normal text-sm">
                            Categories: {totalHours}
                          </p>
                        </div>
                        <div className="flex items-center gap-2">
                          <TbClock24 className="text-[#89868D]" />
                          <p className="text-[#89868D] font-normal text-sm">
                            CE hours: {numbers}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="border-t border-[#B1B1B1] 2xl:px-5 px-3  pt-4 pb-4 flex items-center justify-between">
                      <div className="flex  gap-[10px]">
                        <Link
                          to={`/course-mangement/${_id}`}
                          className=" bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
                        >
                          View
                        </Link>
                        <button
                          className=" bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
                          onClick={() => modelModel(courseItem)}
                        >
                          <FaEdit />
                        </button>
                        <button
                          className=" bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
                          onClick={() =>
                            setDeleteModel({
                              deleteShowType: true,
                              deleteId: _id,
                              deleteItem: name,
                            })
                          }
                        >
                          <MdDelete />
                        </button>
                      </div>
                      {/* <Link
                        to={`/course-mangement/${_id}`}
                        className=" bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
                      >
                        View
                      </Link> */}
                      <p className="text-[#CC4D1D] text-xs font-medium">
                        Monthly Price $:{monthly_price}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <DataEmpty />
          )}
        </>
      ) : (
        <TableLorder />
      )}

      <DeleteModel
        show={deleteModel?.deleteShowType}
        hide={() =>
          setDeleteModel({
            deleteShowType: false,
            deleteId: "",
          })
        }
        deleteItem={deleteModel?.deleteItem}
        confimDelete={() => deleteHndle(deleteModel?.deleteId)}
        deleteLoading={deleteLoading}
      />
    </>
  );
};

export default CourseManagementList;
